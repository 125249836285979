<template>
  <v-list
    :width="300"
    class="pt-0"
    dense
  >
    <v-list-item
      class="bg-gray"
      @click="$emit('update:filter-key', null)"
    >
      <v-list-item-title class="text-white">
        <v-icon
          color="white"
          small
          left
        >
          mdi-arrow-left-circle
        </v-icon>
        Back
      </v-list-item-title>
    </v-list-item>

    <template v-for="operator in operatorTypes">
      <v-list-item
        v-if="operator.usedIn.includes(filterKey.type)"
        :key="operator.key"
        @click="addFilterOperator(operator)">
        <v-list-item-title>
          <v-icon small left>{{ operator.icon }}</v-icon>
          <strong>{{ filterKey.label }}</strong> <span class="operator">{{ operator.label }}</span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>
<script type="text/javascript">
export default {
  name: 'TableFilterOperators',

  props: {
    filterOperator: {
      required: true,
      default: () => { return null }
    },
    filterKey: {
      required: true,
      default: () => { return null }
    }
  },

  data () {
    return {
      baseOperatorTypes: [
        {
          label: 'is',
          key: 'is',
          icon: 'mdi-check-all',
          usedIn: ['String', 'Select']
        },
        {
          label: 'is not',
          key: 'is_not',
          icon: 'mdi-alert-circle-outline',
          usedIn: ['String', 'Select']
        },
        {
          label: 'starts with',
          key: 'starts_with',
          icon: 'mdi-contain-start',
          usedIn: ['String']
        },
        {
          label: 'ends with',
          key: 'ends_with',
          icon: 'mdi-contain-end',
          usedIn: ['String']
        },
        {
          label: 'contains',
          key: 'contains',
          icon: 'mdi-contain',
          usedIn: ['String']
        },
        {
          label: 'is',
          key: 'is_on',
          icon: 'mdi-calendar',
          usedIn: ['Date']
        },
        {
          label: 'is after',
          key: 'is_after',
          icon: 'mdi-calendar',
          usedIn: ['Date']
        },
        {
          label: 'is before',
          key: 'is_before',
          icon: 'mdi-calendar',
          usedIn: ['Date']
        },
        {
          label: 'is equal to',
          key: 'is_equal_to',
          icon: 'mdi-calculator',
          usedIn: ['Number']
        },
        {
          label: 'is greater than',
          key: 'is_greater_than',
          icon: 'mdi-calculator',
          usedIn: ['Number']
        },
        {
          label: 'is less than',
          key: 'is_less_than',
          icon: 'mdi-calculator',
          usedIn: ['Number']
        }
      ]
    }
  },

  computed: {
    operatorTypes () {
      return this.baseOperatorTypes
    }
  },

  methods: {
    addFilterOperator (key) {
      this.$emit('update:filter-operator', key)
    }
  }
}
</script>
<style type="text/css" scoped>
  .operator {
    border-bottom: 1px dotted;
    font-size: small;
    font-style: italic;
  }
</style>